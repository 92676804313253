import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Button } from '@mui/material';
import { RoqLink } from 'modules/common/components/roq-link/';
import { ReactElement } from 'react';
import routes from 'routes';


export const LoginBackLinkComponent = ({ title = 'Back to sign in', onClickHandler }: { title?: string; onClickHandler?: () => void }): ReactElement =>
  <RoqLink {...(onClickHandler ? { onClick: onClickHandler } : { href: { route: routes.login } })} >
    <Button
      sx={{
        color: 'white',
        textTransform: 'none',
      }}
      variant="text" startIcon={<ArrowBackIosNewIcon />}>
      {title}
    </Button>
  </RoqLink>