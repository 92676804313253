// eslint-disable-next-line @roq/filename-suffix-mismatch
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useLoginStyles = makeStyles((theme: Theme) => ({
  title: {
    marginBottom: '0px',
    color: 'black',
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '120%',
  },
  loginText: {
    color: '#626262',
    fontSize: '15px',
    fontWeight: 400,
    lineHeight: '160%',
    letterSpacing: '0.17px',
    marginTop: '12px'
  },
  loginForm: {
    [theme.breakpoints.up('md')]: {
      minWidth: '400px' 
    }
  },
}));
