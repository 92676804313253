import { Avatar, Stack, Typography } from '@mui/material';
import { createUserInitials } from 'modules/auth/utils/user-initials';
import { ReactElement } from 'react';
import { PreLoginUserInterface } from 'views/login/interfaces/prelogin-user.interface';

export const PreLoginUserDetailPartial = (props: { user: PreLoginUserInterface }): ReactElement => {
  const { user } = props;
  const avatarInitial = createUserInitials(user.firstName ? user : {firstName: user.email})
  return <Stack direction={'row'} alignItems={'center'} gap={'8px'}>
    <Avatar
      src={user?.avatar}
      sx={{
        width: '24px',
        height: '24px',
        padding: '2px'
      }}
    >
      {avatarInitial}
    </Avatar>
    <Typography
      sx={{
        color: '#626262',
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '25.12px',
        letterSpacing: '0.17px',
    }}>
      {user.email}
    </Typography>
  </Stack>
}