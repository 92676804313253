import { Divider } from '@mui/material';
import Typography from '@mui/material/Typography';
import { format } from 'date-fns';
import { AuthLayout } from 'layouts/auth';
import { AuthHeadingBlock } from 'modules/auth/components/auth-heading-block';
import { LoginBackLinkComponent } from 'modules/auth/components/login-back-link/login-back-link.component';
import { SSOLoginFormComponent } from 'modules/auth/components/sso-login-form/sso-login-form.component';
import { withAuth } from 'modules/auth/hocs';
import { helmagEmailRegex } from 'modules/auth/utils/helmag-email-regex';
import { MessageBar } from 'modules/common/components/message-bar/message-bar.component';
import { MessageTopBar } from 'modules/common/components/message-top-bar';
import { useTopbarMessage } from 'modules/common/components/message-top-bar/hooks/use-topbar-message.hook';
import { useTranslation } from 'modules/common/hooks';
import { GraphQlErrorsInterface } from 'modules/common/interfaces';
import { TwoFactorAuthenticationWrapperComponent } from 'modules/two-factor-authentication/components/two-factor-authentication-wrapper/two-factor-authentication-wrapper.component';
import { signIn } from 'next-auth/react';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { LoginErrorsTypeEnum } from 'views/login/enums/login-errors-type.enum';
import { LoginTypeEnum } from 'views/login/enums/login-type.enum';
import { useHandlePreLogin, useLogin, useResetError, useUserCookieConsent } from 'views/login/hooks';
import { LoginFormValues } from 'views/login/hooks/use-login.hook';
import { useSetPreLoginEmailCookie } from 'views/login/hooks/use-set-pre-login-email-cookie.hook';
import { useUserPreLogin } from 'views/login/hooks/use-user-pre-login.hook';
import { EmailValuesInterface } from 'views/login/interfaces/email-login-form-props.interface';
import { PreLoginUserInterface } from 'views/login/interfaces/prelogin-user.interface';
import { useLoginStyles } from 'views/login/login.styles';
import { LoginFormPartial } from 'views/login/partials';
import { EmailLoginFormPartial } from 'views/login/partials/email-login-form/email-login-form.partial';
import { PreLoginUserDetailPartial } from 'views/login/partials/prelogin-user-detail/prelogin-user-detail.partial';

export const LoginView = withAuth({ redirectIfAuthenticated: true })((): ReactElement => {
  useResetError();

  const { t } = useTranslation();
  const { initiateOperation: initiateUserPreLogin, isLoading: isUserPreloginLoading, status: userPreloginData } = useUserPreLogin();
  const { error: loginError, resetError: resetLoginError, handleLogin, isLoading, clearStorage } = useLogin();
  const [input, setInput] = useState<LoginFormValues>();
  const { setPreLoginEmailCookie } = useSetPreLoginEmailCookie();
  const [cookiesDeclined, setCookiesDeclined] = useState(false);
  const { showTopbarMessage, hideTopbarMessage } = useTopbarMessage();
  const [loginType, setLoginType] = useState<LoginTypeEnum>(LoginTypeEnum.LOGIN_EMAIL)
  const { status, initiateOperation, isLoading: preLoginLoading } = useHandlePreLogin();
  const [user, setUser] = useState<PreLoginUserInterface>({ email: '', firstName: '', lastName: '', avatar: '' })
  const [emailError, setEmailError] = useState<string>();
  const { setCookieConsent, cookieConsentAvailable } = useUserCookieConsent();

  const [showTwoFactorAuthComponent, setShowTwoFactorAuthComponent] = useState(false);

  const [errorMsg, setErrorMsg] = useState<string>(null);
  const classes = useLoginStyles();

  useEffect(() => {
    if (status?.success) {
      const { isTwoFactorAuthEnabled, isTwoFactorAuthConfigured, isTwoFactorAuthExpired } = status?.result || {};
      if (!isTwoFactorAuthEnabled || !isTwoFactorAuthExpired) {
        handleLogin(input);
      }
      if (isTwoFactorAuthEnabled && (!isTwoFactorAuthConfigured || isTwoFactorAuthExpired)) {
        setShowTwoFactorAuthComponent(true);
      }
    }

    if (status?.error) {
      const gqlErr = status?.error as GraphQlErrorsInterface;
      const LoginAttempts = gqlErr?.graphQLErrors?.[0]?.extensions?.response?.message?.includes('attempts')
        ? gqlErr?.graphQLErrors?.[0]?.extensions?.response?.variables
        : null;
      if (LoginAttempts) {
        const loginTimeout = new Date(LoginAttempts?.date);
        setErrorMsg(`Too many invalid attempts. Try logging in after ${format(loginTimeout, 'hh:mm:aa')}`);

      } else {
        setErrorMsg(status.error.message);
      }
    }
  }, [status]);

  useEffect(() => {
    const error = new URL(window.location.href)?.searchParams?.get('error');
    if (error === LoginErrorsTypeEnum.AccessDenied) {
      showTopbarMessage({ title: 'Unauthorized Access', severity: 'error' })
    }
  }, [])

  useEffect(() => {
    if (!userPreloginData?.result) return;
    const email = userPreloginData.result.email || user.email;
    if (email.match(helmagEmailRegex)) {
      if (!userPreloginData.result.firstName) {
        setEmailError('User with email does not exist')
        return;
      }
      setLoginType(LoginTypeEnum.LOGIN_SSO)
    } else {
      setLoginType(LoginTypeEnum.LOGIN_PASSWORD)
    }

    setUser(userPreloginData.result);

  }, [userPreloginData])

  const backToLogin = () => {
    setShowTwoFactorAuthComponent(false);
  };

  const onCookieConsentAccept = useCallback(() => {
    setCookieConsent();
  }, []);

  const onTwoFactorAuthSubmit = (code: string) => {
    handleLogin({ ...input, code });
  };

  const onDecline = () => {
    setCookiesDeclined(true);
  };

  const handleLoginSubmit = async (args) => {
    setInput({ ...args, email: user.email });
    const { password } = args;
    void clearStorage();
    const result = await initiateOperation({ email: user.email, password });
    if (result instanceof Error) {
      throw result;
    }
  };

  const backToSignInHandler = () => {
    setLoginType(LoginTypeEnum.LOGIN_EMAIL)
    setEmailError('');
    setErrorMsg('');
  }

  const handleEmailSubmit = async (value: EmailValuesInterface) => {
    hideTopbarMessage();
    setUser({ ...user, email: value.email })
    await initiateUserPreLogin({ email: value.email });
  }


  const handleSSOSignIn = async () => {
    await setPreLoginEmailCookie(user?.email);
    await signIn('azure-ad', { email: user?.email }, { login_hint: user.email })
  }

  const errorMessageBar = status?.error && errorMsg && <MessageBar sx={{ mb: '12px' }} color="error" severity="error" title={errorMsg} />
  const emailErrorMessageBar = emailError ? <MessageBar color="error" severity="error" title={emailError} /> : null;

  const authHeading =
    loginType === LoginTypeEnum.LOGIN_PASSWORD ? 'Enter Password'
      : loginType === LoginTypeEnum.LOGIN_SSO ? 'Single Sign On'
        : t('login');

  const authSubHeading =
    loginType === LoginTypeEnum.LOGIN_EMAIL ?
      <p style={{ margin: '0px' }}> Enter your email to sign in.</p>
      : <PreLoginUserDetailPartial user={user} />;

  const loginForm = loginType === LoginTypeEnum.LOGIN_PASSWORD ?
    <LoginFormPartial onSubmit={handleLoginSubmit} loginBtnLoading={preLoginLoading || isLoading} messageBarComponent={errorMessageBar} />
    : loginType === LoginTypeEnum.LOGIN_SSO ?
      <SSOLoginFormComponent handleSSOLogin={handleSSOSignIn} />
      :
      <EmailLoginFormPartial isLoading={isUserPreloginLoading} handleEmailSubmit={handleEmailSubmit} messageBarComponent={emailErrorMessageBar} />

  return showTwoFactorAuthComponent ? (
    <TwoFactorAuthenticationWrapperComponent
      twoFactorConfiguration={status?.result}
      onTwoFactorAuthSubmit={onTwoFactorAuthSubmit}
      loginError={loginError}
      resetLoginError={resetLoginError}
      backToLogin={backToLogin}
    />
  ) : (
    <>
      <AuthLayout
        title={t('login')}
        visible={!cookieConsentAvailable && !cookiesDeclined}
        onAccept={onCookieConsentAccept}
        onDecline={onDecline}
        back={
          loginType !== LoginTypeEnum.LOGIN_EMAIL ?
            <LoginBackLinkComponent title='Sign in with another account' onClickHandler={backToSignInHandler} />
            : null
        }
        noAside
      >
        <AuthHeadingBlock>
          <Typography component="h1" className={classes.title} variant="h3" gutterBottom>
            {authHeading}
          </Typography>

          <div className={classes.loginText}>
            {authSubHeading}
          </div>
        </AuthHeadingBlock>
        <Divider variant='fullWidth' />


        <MessageTopBar />
        <div className={classes.loginForm}>
          {loginForm}
        </div>
      </AuthLayout>
    </>
  );
});
