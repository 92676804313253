import { LoadingButton } from '@mui/lab';
import { Box, InputLabel } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { RoqLink } from 'modules/common/components/roq-link';
import { useTranslation } from 'modules/common/hooks';
import { PasswordField } from 'modules/forms/components';
import { useEnhancedFormik } from 'modules/forms/hooks';
import { FunctionComponent, ReactElement } from 'react';
import routes from 'routes';
import { useLoginFormSchema } from 'views/login/hooks';
import { useLoginFormStyles } from 'views/login/partials/login-form/login-form.styles';

export interface LoginFormValues {
  password: string;
  keepMeLoggedIn: boolean;
}

export interface LoginFormPartialInterface {
  onSubmit: (formValues: LoginFormValues) => void;
  loginBtnDisabled?: boolean;
  loginBtnLoading: boolean;
  messageBarComponent?: ReactElement
}

const initialValues: LoginFormValues = {
  password: '',
  keepMeLoggedIn: true,
};

export const LoginFormPartial: FunctionComponent<LoginFormPartialInterface> = (props) => {
  const { onSubmit, loginBtnLoading, messageBarComponent } = props;
  const { t } = useTranslation();
  const classes = useLoginFormStyles();

  const { handleSubmit, handleChange, values, visibleErrors, setFieldValue, handleBlur, isSubmitting, status } =
    useEnhancedFormik({
      initialValues,
      onSubmit,
      validationSchema: useLoginFormSchema(),
    });

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid container padding={'24px'} xs={12}>
          {messageBarComponent}
          <Box width={'100%'}>
            <InputLabel sx={{ mb: '6px !important' }} htmlFor="password">{t('input.password.placeholder')}</InputLabel>
            <PasswordField
              id="password"
              name="password"
              value={values.password}
              size="small"
              placeholder='Password'
              helperText={<span data-cy="password-helper-text">{visibleErrors.password}</span>}
              error={Boolean(visibleErrors.password)}
              fullWidth
              inputProps={{
                autoComplete: 'current-password',
              }}
              sx={{
                'input:placeholder-shown': {
                  fontStyle: "italic"
                },
                margin: '0px !important',
                mb: '4px'
              }}
              onChange={handleChange}
              onReset={() => setFieldValue('password', '')}
              onBlur={handleBlur}
              variant="filled"
            />
          </Box>

        <Grid container mt={'8px'} direction="row" justifyContent="space-between" alignItems="center">
          <Grid item></Grid>

          <Grid item>
            <Typography component="p">
              <RoqLink
                data-cy="forgot-password-link"
                className={classes.forgotPassword}
                href={{ route: routes.forgotPassword }}
              >
                {t('forgot-password-cta')}
              </RoqLink>
            </Typography>
          </Grid>
        </Grid>

        <LoadingButton
          disabled={isSubmitting || status?.success}
          loading={loginBtnLoading}
          type="submit"
          variant="contained"
          color="primary"
          size="xlarge"
          fullWidth
          sx={{ height: '48px', mt:'12px' }}
        >
          {t('login')}
          </LoadingButton>
        </Grid>

      </form>
    </>
  );
};
