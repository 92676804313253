import { LoadingButton } from '@mui/lab'
import { Box, Grid, InputLabel, TextField } from '@mui/material'
import { useTranslation } from 'modules/common/hooks'
import { useEnhancedFormik } from 'modules/forms/hooks'
import { ReactElement } from 'react'
import { useEmailLoginSchema } from 'views/login/hooks/use-email-login-schema.hook'
import { EmailLoginFormPropsInterface } from 'views/login/interfaces/email-login-form-props.interface'

const defaultValues = {
  email: ''
}

export const EmailLoginFormPartial = (props: EmailLoginFormPropsInterface): ReactElement => {
  const { handleEmailSubmit, value: initialValue, isLoading = false, messageBarComponent } = props;
  const { t } = useTranslation();
  const { values, visibleErrors, setFieldValue, handleBlur, handleChange, handleSubmit, isSubmitting } = useEnhancedFormik({
    initialValues: initialValue ? { email: initialValue } : defaultValues,
    onSubmit: (value) => handleEmailSubmit(value),
    validationSchema: useEmailLoginSchema()
  })
  return <form onSubmit={handleSubmit} style={{ width: '100%' }}>
    <Grid container rowGap={'12px'} padding={'24px'} xs={12}>
      <Box width={'100%'}>
        {messageBarComponent}
        <InputLabel sx={{ mb: '6px !important' }} htmlFor="email">{t('input.email.placeholder')}</InputLabel>
        <TextField
          id="email"
          name="email"
          type="email"
          autoFocus={true}
          value={values.email}
          helperText={<span data-cy="email-helper-text">{visibleErrors.email}</span>}
          error={Boolean(visibleErrors.email)}
          size="small"
          fullWidth
          placeholder='your@email.com'
          inputProps={{
            autoComplete: 'username',
          }}
          onChange={handleChange}
          onReset={() => setFieldValue('email', '')}
          onBlur={handleBlur}
          variant="filled"
          sx={{
            'input:placeholder-shown': {
              fontStyle: "italic"
            },
            margin: '0px !important'
          }}
        />
      </Box>
      <LoadingButton
        disabled={isSubmitting}
        loading={isLoading}
        type="submit"
        variant="contained"
        color="primary"
        size="xlarge"
        fullWidth
        sx={{ height: '48px' }}
      >
        Next
      </LoadingButton>
    </Grid>
  </form>
}