import { gql } from '@apollo/client'
import { useAsyncOperation } from 'modules/common/hooks'
import { UseAsyncHookInterface } from 'modules/common/hooks/use-async-operation.hook'
import { requestGql } from 'modules/common/utils/request-gql'
import { PreLoginUserInterface } from 'views/login/interfaces/prelogin-user.interface';

interface UserPreLoginInput {
  email: string;
}


export type UseUserPreLogin = UseAsyncHookInterface<UserPreLoginInput, PreLoginUserInterface>

export const useUserPreLogin = (): UseUserPreLogin => {
  const userPreLogin = ({ email }: UserPreLoginInput): Promise<PreLoginUserInterface> =>
    requestGql({
      query: gql`query UserPreLogin($email: String!) {
       userPreLogin(email: $email) {
        firstName
        lastName
        email
        avatar
       }
      }`,
      variables: {
        email
      }
    }, null, 'userPreLogin')
  
  return useAsyncOperation<UserPreLoginInput, PreLoginUserInterface>({
    callback: userPreLogin
  })
}